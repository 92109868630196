<template>
  <v-row justify="center" class="my-4 mx-0">
    <!-- contain different categories -->
    <!-- contain router view -->
    <v-col cols="12" md="3">
      <v-btn
        @click="navigateToGroup(group)"
        v-for="group in faqGroups"
        :key="group.id"
        width="100%"
        class="mb-2"
        x-large
        :color="`primary ${group.id !== selectedFaqGroupId && 'lighten-3'}`"
      >
        {{ group.name }}
      </v-btn>
    </v-col>
    <v-col cols="12" md="5">
      <RouterView />
    </v-col>
  </v-row>
</template>

<script>
import { fetchAllFaqGroups } from '@/api/faq';

export default {
  name: 'FaqLayout',
  data: () => ({
    faqGroups: [],
    selectedFaqGroupId: null
  }),
  created() {
    this.setBreadCrumbs();
    this.getFaqGroups();
  },
  watch: {
    $route() {
      if (!this.$route.params?.id && this.faqGroups) {
        this.navigateToFirstFaqGroup();
      }
    }
  },
  computed: {
    // Get selected FaqGroup based on selected FaqGroupId
    selectedFaqGroup() {
      return (
        this.selectedFaqGroupId &&
        this.faqGroups.filter(
          faqGroup => faqGroup.id === this.selectedFaqGroupId
        )[0]
      );
    }
  },
  methods: {
    async getFaqGroups() {
      const response = await fetchAllFaqGroups();
      this.faqGroups = response.data;

      this.selectedFaqGroupId = Number.parseInt(this.$route.params.id);
      this.selectFirstFaqGroup();
    },
    selectFirstFaqGroup() {
      if (this.faqGroups.length) {
        this.navigateToFirstFaqGroup();
      }
    },
    navigateToFirstFaqGroup() {
      this.navigateToGroup(this.faqGroups[0]);
    },
    navigateToGroup(group) {
      if (
        this.selectedFaqGroupId === group.id &&
        this.selectedFaqGroupId === Number.parseInt(this.$route.params.id)
      )
        return;

      this.selectedFaqGroupId = group.id;
      this.$router.push({
        name: 'faq.questions',
        params: {
          id: this.selectedFaqGroup.id,
          name: this.selectedFaqGroup.name
        }
      });
    },
    setBreadCrumbs() {
      this.$hero.$emit('setTitle', 'FAQ');
      this.$hero.$emit('setCanSearch', false);
      this.$hero.$emit('setBreadcrumbs', [
        {
          text: 'home',
          exact: true,
          to: { name: 'home' }
        },
        {
          text: 'faqs',
          to: {
            name: 'faq.questions'
          }
        }
      ]);
    }
  }
};
</script>
